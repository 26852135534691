<template>
  <Sidebar
    :loading="!!loading"
    :instance="instance"
    @close="$router.push({ name: 'order.index' })"
  >
    <template v-if="instance">
      <OrderLayout>
        <div class="mb-5 d-flex">
          <h1>
            {{
              instance.uuid
                ? name
                  ? name
                  : `Edit order #${instance.num}`
                : `New order`
            }}
          </h1>
          <v-icon @click="editTitle()" v-if="client" class="ml-2"
            >mdi-pencil</v-icon
          >
        </div>

        <div v-if="client && isTitleEditable" class="mb-5">
          <v-text-field
            label="Order name"
            v-model="name"
            :error-messages="getErrors('name')"
            @change="unsetError('name')"
            outlined
          />
        </div>

        <div class="mb-5">
          <ClientPicker
            v-model="client"
            @change="unsetError('client_uuid')"
            :error-messages="getErrors('client_uuid')"
            :hide-details="!hasErrors('client_uuid')"
          ></ClientPicker>
        </div>

        <div v-if="client">
          <div class="mb-5">
            <v-textarea
              no-resize
              rows="3"
              label="Description"
              placeholder="You can briefly describe your order"
              outlined
              v-model="description"
              :error-messages="getErrors('description')"
              @change="unsetError('description')"
            ></v-textarea>
          </div>

          <div class="mb-5">
            <header class="d-flex align-center mb-3">
              <div class="mr-2">
                <h4>Route</h4>
                <div
                  v-if="hasErrors(`route`)"
                  class="text-right text-caption error--text"
                  v-text="getErrors(`route`)"
                ></div>
              </div>
              <v-btn
                @click="() => addWaypoint()"
                color="grey lighten-4"
                small
                depressed
                class="px-0"
              >
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
            </header>

            <div class="waypoints">
              <div class="mb-3" v-for="(waypoint, i) in route" :key="`wp-${i}`">
                <v-row dense :key="`r-${i}`">
                  <v-col cols="11">
                    <Waypoint
                      :key="`route-${i}`"
                      :index="i"
                      :errors="routeErrors(i)"
                    >
                      <template v-slot:location>
                        <LocationPicker v-model="waypoint.location" />
                      </template>
                      <template v-slot:transport>
                        <TransportSelect v-model="waypoint.transport" />
                      </template>
                      <template v-slot:state>
                        <StatePicker
                          v-bind:state.sync="waypoint.state"
                          v-bind:timestamp.sync="waypoint.current_timestamp"
                        />
                      </template>
                    </Waypoint>
                  </v-col>
                  <v-col cols="1" class="text-right">
                    <v-btn
                      :disabled="route.length < 2"
                      icon
                      small
                      plain
                      @click="deleteWaypoint(i)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </OrderLayout>

      <v-spacer></v-spacer>

      <v-app-bar
        height="100px"
        fixed
        bottom
        elevation="5"
        color="white"
        v-if="instance"
      >
        <v-list-item>
          <v-list-item-avatar size="52" v-if="defaultManager">
            <Avatar size="52" :subject="defaultManager"></Avatar>
          </v-list-item-avatar>
          <v-list-item-content v-if="defaultManager">
            <v-list-item-title>{{ defaultManager.name }}</v-list-item-title>
            <v-list-item-subtitle>Assigned manager</v-list-item-subtitle>
          </v-list-item-content>
          <v-spacer v-if="!defaultManager"></v-spacer>
          <v-list-item-action>
            <div>
              <v-btn
                :disabled="!!loading"
                plain
                class="mr-2"
                @click="$router.go(-1)"
                >Cancel</v-btn
              >
              <v-btn
                :loading="!!loading"
                depressed
                color="success"
                @click="
                  () => {
                    clearErrors();
                    save();
                  }
                "
                >Save
              </v-btn>
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-app-bar>
    </template>
  </Sidebar>
</template>

<script>
import Sidebar from "../../components/layout/Sidebar";
import { mapActions, mapGetters, mapState } from "vuex";
import ClientPicker from "../../components/order/ClientPicker";
import { concat, get } from "lodash";
import Avatar from "../../components/common/Avatar";
import Router from "../../components/order/Router";
import { mapFields, mapMultiRowFields } from "vuex-map-fields";
import LocationPicker from "../../components/order/LocationPicker";
import TransportSelect from "../../components/order/TransportSelect";
import StatePicker from "../../components/order/StatePicker";
import Waypoint from "../../components/order/Waypoint";
import OrderLayout from "../../components/layout/OrderLayout";

export default {
  name: "OrderWizard",
  components: {
    OrderLayout,
    Waypoint,
    StatePicker,
    // eslint-disable-next-line vue/no-unused-components
    TransportSelect,
    LocationPicker,
    /* Router,*/ Avatar,
    ClientPicker,
    /*ClientCard,*/ Sidebar,
  },
  props: ["uuid"],
  data() {
    return {
      isTitleEditable: false,
    };
  },
  computed: {
    ...mapState("order/instance", {
      instance: "instance",
      loading: "loading",
    }),
    ...mapFields("order/instance", {
      name: "instance.name",
      manager_uuid: "instance.manager_uuid",
      client_uuid: "instance.client_uuid",
      client: "instance.client",
      description: "instance.description",
    }),
    ...mapMultiRowFields("order/instance", {
      route: "instance.route",
    }),
    ...mapGetters("error", {
      hasErrors: "hasErrors",
      getErrors: "getErrors",
    }),
    routeErrors() {
      return (i) =>
        concat(
          this.getErrors(`route.${i}.location`),
          this.getErrors(`route.${i}.transport`),
          this.getErrors(`route.${i}.state`),
          this.getErrors(`route.${i}.current_timestamp`)
        );
    },
    defaultManager() {
      return get(this.client, "company.manager");
    },
  },
  methods: {
    ...mapActions("order/instance", {
      destroy: "destroy",
      addWaypoint: "addWaypoint",
      deleteWaypoint: "deleteWaypoint",
      saveInstance: "save",
    }),
    ...mapActions("error", {
      unsetError: "unset",
      clearErrors: "clear",
    }),
    editTitle() {
      this.isTitleEditable = !this.isTitleEditable;
    },
    save() {
      this.manager_uuid = get(this.defaultManager, "uuid", null);
      this.client_uuid = get(this.client, "uuid", null);
      this.saveInstance();
    },
    confirmAndDelete() {
      if (window.confirm("Are you sure?")) {
        return this.destroy();
      }
    },
  },

  mounted: function () {
    let elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflowY = "hidden";
  },
  beforeDestroy: function () {
    this.clearErrors();
    let elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflowY = null;
  },
};
</script>
