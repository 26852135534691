<template>
  <v-menu v-model="menu" offset-y :close-on-click="false" :close-on-content-click="false" min-width="500"
          :disabled="readonly">
    <template v-slot:activator="{ on, attrs }">
      <v-chip
          label
          :class="`status status--${state} rounded-sm`"
          v-bind="attrs" v-on="on">
        <span class="font-weight-medium">{{ state || 'Pick status' }} {{ timestamp }}</span>
      </v-chip>
    </template>
    <template v-slot:default>
      <v-card>
        <v-row>
          <v-col>
            <v-list dense flat>
              <v-list-item-group @change="updateState" :value="state" mandatory>
                <v-list-item
                    v-for="item in states"
                    :key="item.value"
                    :value="item.value"
                    v-slot="{ active }"
                >
                  <v-chip
                      :class="active ? 'rounded-sm' : `status status--${item.value} rounded-sm`"
                      :input-value="active"
                      label
                      filter
                  ><span class="font-weight-medium">{{ item.text }}</span></v-chip>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col>
            <v-date-picker color="primary"
                           no-title
                           flat
                           :value="timestamp"
                           :weekday-format="(d) => {
                             let i = new Date(d).getDay(d);
                             return ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'][i];
                           }"
                           first-day-of-week="0"
                           @change="updateTimestamp"
            ></v-date-picker>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain @click="menu = false">Cancel</v-btn>
          <v-btn depressed color="success" @click="save()" :disabled="!state || !timestamp">Save</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-menu>
</template>

<script>
import {get, find} from 'lodash';
import moment from "moment";
import states from "../../store/enums/states";

export default {
  name: "StatePicker",
  props: {
    readonly: {
      default: false,
    },
    state: {
      type: String,
      default: null,
    },
    timestamp: {
      type: [String],
      default: null,
    }
  },
  data: () => ({
    menu: false,
    states: [...states],
  }),
  methods: {
    mapStateColor(state) {
      const found = find(this.states, (e) => e.value === state);
      if (found) return found.color;
      return 'error';
    },
    updateState(v) {
      this.$emit('update:state', v)
    },
    updateTimestamp(v) {
      this.$emit('update:timestamp', v)
    },
    save() {
      this.$emit('input', this.buffer);
      this.menu = false;
    }
  }
}
</script>