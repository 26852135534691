<template>
  <v-card outlined>
    <v-autocomplete
        class="client-picker"
        solo flat
        :value="value"
        @change="v => {
          $emit('input', v);
          $emit('change', v);
        }"
        :filter="filter"
        :loading="loading"
        :items="items"
        :return-object="true"
        item-value="uuid"
        append-icon=""
        placeholder="Choose client"
        clearable
        :open-on-clear="true"
        :allow-overflow="false"
        :hide-details="hideDetails"
        :error-messages="errorMessages"
    >
      <template v-slot:selection="{item}">
        <div class="d-flex align-start flex-nowrap justify-start size-5" style="width: 100%; max-width: 100%;">
          <div class="pa-2 text-truncate" :title="item.company.name">
            <v-list-item-title class="text-h6 mb-2 font-weight-bold">{{ item.company.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.phone ? item.phone : '―' }}</v-list-item-subtitle>
            <v-list-item-subtitle class="mb-5">{{ item.email ? item.email : '―' }}</v-list-item-subtitle>
            <v-list-item-subtitle>Contact person: {{ item.name }}</v-list-item-subtitle>
          </div>
          <div class="pa-2 ml-auto">
            <Avatar class="mt-2" :subject="item"></Avatar>
          </div>
        </div>
      </template>
      <template v-slot:item="{item}">
        <template v-if="typeof item !== 'object'">
          <v-list-item-content v-text="item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title>{{ item.company.name || item.name }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </v-card>
</template>

<script>
import http from '../../http/api';
import {map} from "lodash";
import {mapActions, mapState} from "vuex";
import Avatar from "../common/Avatar";

export default {
  name: "ClientPicker",
  components: {Avatar},
  props: {
    value: {
      default: () => null
    },
    errorMessages: {
      default: () => [],
    },
    hideDetails: {
      default: false,
    }
  },
  computed: {
    ...mapState('registry', {
      items: 'client',
      loading: 'loading.client'
    }),
  },
  methods: {
    ...mapActions('registry', {
      load: 'load'
    }),
    filter: (item, queryText, itemText) => {
      return (
          (item.name.replace(/[^\d|\w]+/g, '').toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
              itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
          ||
          (item.login.replace(/[^\d|\w]+/g, '').toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
              itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
          ||
          (item.company.name.replace(/[^\d|\w]+/g, '').toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
              itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      )
    }
  },
  mounted() {
    if (this.items.length === 0) this.load('client');
  }
}

</script>

<style lang="css">
.v-input.client-picker.v-input--is-focused.v-select--is-menu-active div.v-input__control div.v-input__slot div.v-select__slot div.v-select__selections input {
  /* OK */
}
.v-input.client-picker.v-input--is-dirty.v-input--is-focused.v-select--is-menu-active div.v-input__control div.v-input__slot div.v-select__slot div.v-select__selections > .d-flex + input {
  max-width: 0 !important;
  min-width: 0 !important;
}
</style>