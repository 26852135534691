<template>
  <div>
    <template v-for="(item,index) in value">
      <v-row dense :key="`wp-${index}`">
        <v-col cols="11">
          <slot name="item" v-bind="{item, index}"></slot>
        </v-col>
        <v-col cols="1" class="d-flex align-start justify-center">
          <v-btn icon>
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  name: "Router",
  props: [
      'value'
  ],
  methods: {
    add() {
      this.$emit('add', null);
    },
    remove(index) {
      this.$emit('remove', index);
    }
  }
}
</script>