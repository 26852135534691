<template>
  <v-container class="px-10">
    <v-row>
      <v-col md="9">
        <div style="margin-bottom: 100px">
          <slot></slot>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "OrderLayout"
}
</script>